import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const About = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        title="O mnie"
        keywords={[`borkowska`, `adwokat`, `gdańsk`, `gdansk`, `kancelaria`]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <figure className="kg-card kg-image-card kg-width-full">
            <Img
              fluid={data.picture.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption>adw. Agnieszka Borkowska</figcaption>
          </figure>
          <h3 id="o-mnie">O mnie</h3>
          <p>
            Adwokat Agnieszka Borkowska jest absolwentką Wydziału Prawa
            i&nbsp;Administracji{" "}
            <strong>Uniwersytetu Mikołaja Kopernika</strong> w&nbsp;Toruniu.
            Podczas studiów wyższych odbywała pierwsze praktyki zawodowe.
            W&nbsp;2015 roku odbyła staż w&nbsp;
            <strong>Parlamencie Europejskim</strong>. W&nbsp;latach 2017 – 2019
            odbywała aplikację adwokacką przy{" "}
            <strong>Pomorskiej Izbie Adwokackiej w&nbsp;Gdańsku</strong> oraz
            pogłębiała doświadczenie zawodowe. W&nbsp;2020 roku zdała egzamin
            adwokacki i&nbsp;została wpisana na listę adwokatów{" "}
            <strong>Pomorskiej Izby Adwokackiej w&nbsp;Gdańsku</strong>. Od
            października 2020 roku prowadzi samodzielną praktykę adwokacką.
          </p>
          <p>
            Adwokat Agnieszka Borkowska prowadzi kompleksową obsługę prawną osób
            fizycznych oraz przedsiębiorców na terenie całego kraju. Kancelaria
            specjalizuje się w&nbsp;prawie rodzinnym, cywilnym
            i&nbsp;gospodarczym.
          </p>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    picture: file(relativePath: { eq: "DOR_9750popr.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const AboutPage = props => (
  <StaticQuery
    query={indexQuery}
    render={data => <About location={props.location} data={data} {...props} />}
  />
)
export default AboutPage
